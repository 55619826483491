import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'phoneSavedSuccessToast.header',
    defaultMessage: 'Votre numéro a été enregistré avec succès !',
  },
  description: {
    id: 'phoneSavedSuccessToast.description',
    defaultMessage: 'Notre équipe va vous contacter par téléphone',
  },
});
