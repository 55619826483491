import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BasePhoneInput from 'react-phone-number-input';

import themeFoundation from '@/designSystem/theme';
import { UseFormInputBlur, UseFormInputChange } from '@/hooks/useForm';
import Helper from '@/Molecules/Helper/Helper';

import messages from './messages';

import useStyles from './styles';

type Props = {
  name: string;
  onChangeForUseForm: UseFormInputChange<string>;
  onBlurForUseForm: UseFormInputBlur<string>;
  hasError: boolean;
};

const PhoneInput: FC<Props> = ({
  name,
  onChangeForUseForm,
  onBlurForUseForm,
  hasError,
}) => {
  const { classes } = useStyles({ hasError });
  const { formatMessage } = useIntl();

  const handleChange = (value: string) => {
    onChangeForUseForm({ name, value });
  };

  const handleBlur = (e) => {
    onBlurForUseForm({ name, value: e.target.value });
  };

  return (
    <div className={classes.container}>
      <BasePhoneInput
        name={name}
        onChange={handleChange}
        defaultCountry="FR"
        limitMaxLength
        placeholder={formatMessage(messages.phoneLabel)}
        numberInputProps={{ className: classes.numberInput }}
        className={classes.input}
        onBlur={handleBlur}
        international
      />
      {hasError ? (
        <Helper
          color={themeFoundation.palette.common.white}
          identifier={<FormattedMessage {...messages.phoneErrorIdentifier} />}
          text={<FormattedMessage {...messages.phoneErrorText} />}
        />
      ) : null}
    </div>
  );
};

export default PhoneInput;
