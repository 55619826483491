import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import maxWidthContainer from '@/styles/maxWidth';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    backgroundColor: palette.supporting.blue[300],
    padding: 0,
    [breakpoints.up('lg')]: {
      padding: `0 ${rem(20)}`,
    },
  },
  centerContainer: {
    margin: '0 auto',
    ...maxWidthContainer,
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: rem(20),
    padding: `${rem(20)} ${rem(16)}`,
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: `${rem(16)} 0`,
      gap: rem(12),
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: rem(8),
  },
  right: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexShrink: 0,
    gap: rem(12),
    width: '100%',
    [breakpoints.up('lg')]: {
      width: 'unset',
      flexDirection: 'row',
    },
  },
  description: {
    textAlign: 'justify',
    color: palette.common.white,
    [breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  closeButtonContainer: {
    position: 'absolute',
    top: rem(4),
    right: rem(4),
    alignSelf: 'center',
    [breakpoints.up('lg')]: {
      position: 'unset',
    },
  },
  closeButtonIcon: {
    color: palette.common.white,
    height: rem(20),
    width: rem(20),
  },
}));
