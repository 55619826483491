import { isValidPhoneNumber } from '@les-sherpas/sherpas-toolbox';

const tvBannerFieldsConfig = [
  {
    name: 'phoneNumber',
    // defaultValue: '',
    validators: [
      {
        validator: (phoneNumber: string) =>
          !!phoneNumber &&
          isValidPhoneNumber({
            phoneNumber,
            shouldBeFrenchOnly: false,
          }),
        message: 'phoneNumberInvalid',
      },
    ],
  },
];

export default tvBannerFieldsConfig;
