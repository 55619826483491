import { useEffect, useState } from 'react';

import useLocalStorage from '@/hooks/useLocalStorage';

function useStateStorage<T>(
  key: string,
  initialValue?: T | undefined
): [T | undefined, (_value: T) => void] {
  const { getItem, setItem } = useLocalStorage(key);
  const [storedValue, setStoredValue] = useState<T | undefined>(initialValue);

  const setItemAndStoredValue = (value: T) => {
    setItem(value);
    setStoredValue(value);
  };

  useEffect(() => {
    const ls = getItem() as T | undefined;
    setStoredValue(ls);
  }, [getItem]);

  return [storedValue, setItemAndStoredValue];
}

export default useStateStorage;
