import { FC, ReactElement } from 'react';
import { Slide, Typography } from '@mui/material';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

import Button from '@/designSystem/Button/Button';

import useStyles from './styles';

type ToastProps = SnackbarProps & {
  header: string | ReactElement;
  description: string | ReactElement;
  icon: ReactElement;
};

const SlideTransition = (props) => <Slide {...props} direction="up" />;

export const DEFAULT_CLOSE_DURATION = 3_000;

const Toast: FC<ToastProps> = ({
  header,
  description,
  icon,
  onClose,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      TransitionComponent={SlideTransition}
      onClose={onClose}
      {...rest}
    >
      <div className={classes.toast}>
        {icon}
        <div>
          <Typography
            variant="bodyLgBold"
            component="h3"
            className={classes.title}
          >
            {header}
          </Typography>
          <Typography
            variant="bodyMd"
            component="p"
            className={classes.description}
          >
            {description}
          </Typography>
        </div>
        <div className={classes.ctaClose}>
          <Button
            variant="tertiary"
            iconColor="white"
            onClick={(_) => onClose(null, null)}
            icon="x"
          />
        </div>
      </div>
    </Snackbar>
  );
};

export default Toast;
