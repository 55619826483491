import { PublicTvCampaignCreateLeadRequest } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

const createLeadWithPhoneNumber = async (phoneNumber: string) => {
  try {
    const body: PublicTvCampaignCreateLeadRequest = { phoneNumber };
    await axios.post(`${server}tv-campaign/public/create-lead`, body, {
      withCredentials: true,
      validateStatus: (status) => status === 200,
    });
    return true;
  } catch (e) {
    return false;
  }
};

export default createLeadWithPhoneNumber;
