import { FC } from 'react';
import { Typography } from '@mui/material';

import useAuthentication from '@/components/Authentication/useAuthentication';
import createLeadWithPhoneNumber from '@/components/TVBanner/API/createLeadWithPhoneNumber';
import PhoneInput from '@/components/TVBanner/PhoneInput/PhoneInput';
import PhoneSavedSuccessToast from '@/components/TVBanner/PhoneSavedSuccessToast/PhoneSavedSuccessToast';
import tvBannerFieldsConfig from '@/components/TVBanner/TVBannerFields';
import Button from '@/designSystem/Button/Button';
import useForm from '@/hooks/useForm';
import { useQueryImmutable } from '@/hooks/useQueryImmutable';
import useStateStorage from '@/hooks/useStateStorage';
import CloseButton from '@/Molecules/CloseButton/CloseButton';
import { apiGetActiveCampaign } from '@/shared/API/apiGetActiveCampaign';
import { useToastContext } from '@/shared/Toast/Toast.context';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';
import { useIsMobile } from '@/utils/useResponsive';

import useStyles from './styles';

const TVBanner: FC = () => {
  const { data: tvCampaign } = useQueryImmutable(
    ['tv-campaign/public/get-active-campaign'],
    apiGetActiveCampaign
  );
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const { addToast } = useToastContext();
  const { authentication } = useAuthentication();
  const [isClosed, setIsClosed] = useStateStorage<string>(
    'tv_banner_closed',
    'closed'
  );
  const { values, isFormValid, errors, handleInputBlur, handleInputChange } =
    useForm<{ phoneNumber: string }>({ fields: tvBannerFieldsConfig });

  const closeBanner = () => setIsClosed('closed');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid) return;

    createLeadWithPhoneNumber(values.phoneNumber);
    handleSendEventGTM('lead_call_requested_banner_top', {
      phone_number: values.phoneNumber,
    });
    addToast(<PhoneSavedSuccessToast />);
    closeBanner();
  };

  const isNotAuthenticated = authentication?.authData
    ? !authentication.isAuthenticated
    : false;
  const canShow = isNotAuthenticated && tvCampaign && !isClosed;

  return canShow ? (
    <div className={cx(classes.container)}>
      <div className={classes.centerContainer}>
        <div className={classes.content}>
          <div className={classes.left}>
            <Typography
              variant={isMobile ? 'bodyLgBold' : 'bodyXlBold'}
              component="p"
              className={classes.description}
            >
              {tvCampaign.description}
            </Typography>
          </div>
          <div className={classes.right}>
            <form onSubmit={handleSubmit} className={classes.right}>
              <PhoneInput
                name="phoneNumber"
                onChangeForUseForm={handleInputChange}
                onBlurForUseForm={handleInputBlur}
                hasError={!!errors?.phoneNumber?.length}
              />
              <Button
                size="medium"
                isFullWidth
                type="submit"
                label={tvCampaign.buttonLabel}
              />
            </form>
            <CloseButton
              className={classes.closeButtonContainer}
              classIcon={classes.closeButtonIcon}
              onClick={closeBanner}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default TVBanner;
