import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints, shadow }) => ({
  toast: {
    backgroundColor: palette.neutral[200],
    boxShadow: shadow.high,
    borderRadius: rem(12),
    display: 'flex',
    flexDirection: 'column',
    padding: `${rem(20)} ${rem(16)}`,
    gap: rem(8),
    maxWidth: rem(620),
    [breakpoints.up('md')]: {
      padding: rem(20),
      flexDirection: 'row',
    },
  },
  title: {
    color: palette.common.white,
    marginRight: rem(44),
  },
  description: {
    color: palette.neutral[700],
  },
  ctaClose: {
    position: 'absolute',
    right: rem(0),
    top: rem(0),
  },
}));
