import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  hasError: boolean;
};

export default makeStyles<Props>()(
  ({ palette, breakpoints }, { hasError }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(4),
      width: '100%',
    },
    input: {
      height: rem(44),
      background: palette.common.white,
      border: hasError
        ? `1px solid ${palette.supporting.red[300]}`
        : `1px solid ${palette.neutral[700]}`,
      borderRadius: rem(8),
      padding: rem(12),
      width: '100%',
    },
    numberInput: {
      height: rem(44),
      border: hasError
        ? `1px solid ${palette.supporting.red[300]}`
        : `1px solid ${palette.neutral[700]}`,
      borderRight: 'none',
      borderLeft: hasError && `1px solid ${palette.neutral[700]}`,
      fontSize: rem(16),
      padding: rem(12),
      [breakpoints.up('lg')]: {
        width: rem(228),
      },
      '&:focus': {
        outline: 'none',
      },
    },
    helper: {
      height: rem(20),
    },
  })
);
