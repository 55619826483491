import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@/designSystem/Icon/Icon';
import Toast, { DEFAULT_CLOSE_DURATION } from '@/shared/Toast/common/Toast';
import { useToastContext } from '@/shared/Toast/Toast.context';

import messages from './messages';

const PhoneSavedSuccessToast: FC = () => {
  const { onClose } = useToastContext();

  return (
    <Toast
      autoHideDuration={DEFAULT_CLOSE_DURATION}
      open
      header={<FormattedMessage {...messages.header} />}
      description={<FormattedMessage {...messages.description} />}
      icon={<Icon icon="checkCircle" color="success" size="large" />}
      onClose={onClose}
    />
  );
};

export default PhoneSavedSuccessToast;
