import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneLabel: {
    id: 'landing.tv.banner.phone.label',
    defaultMessage: 'Indiquez votre téléphone',
  },
  phoneErrorIdentifier: {
    id: 'onboarding.student.form.phone.error.identifier',
    defaultMessage: 'Erreur :',
  },
  phoneErrorText: {
    id: 'onboarding.student.form.phone.error.text',
    defaultMessage: 'Merci de renseigner un numéro de mobile valide',
  },
});
